import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Grid, Paper, Tab, Tabs, CircularProgress } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { DataGrid } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import { getUserId } from '../../auth/auth';
import { BASE_URL } from '../../Constants';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';

const SupplierRequests = () => {
  const [tabValue, setTabValue] = useState(0);
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userCanApproveReject, setUserCanApproveReject] = useState(false);
  const [formValues, setFormValues] = useState({
    supplierName: '',
    cost: '',
    description: '',
    dateEstimate: null,
    nextPurchaseDate: null,
  });
  const [formErrors, setFormErrors] = useState({});
  
  // These IDs would need to be replaced with actual IDs of users who can approve/reject
  const approverIds = [2, 95, 72, 88]; // Updated with actual approver IDs
  
  // Add this state to track the current page
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  
  // Add state to track which row is currently being updated
  const [updatingRowId, setUpdatingRowId] = useState(null);
  
  // Add these new state variables
  const [noteDialogOpen, setNoteDialogOpen] = useState(false);
  const [noteText, setNoteText] = useState('');
  const [pendingAction, setPendingAction] = useState({ id: null, status: null });
  
  // Add these new state variables
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editFormValues, setEditFormValues] = useState({
    id: null,
    supplierName: '',
    cost: '',
    description: '',
    dateEstimate: null,
    nextPurchaseDate: null,
  });
  const [editFormErrors, setEditFormErrors] = useState({});
  
  useEffect(() => {
    // Get current user information
    const fetchUserInfo = async () => {
      try {
        // Get the current user ID from the auth utility
        const userId = getUserId();
        // Check if the current user is an approver based on ID only
        setUserCanApproveReject(approverIds.includes(Number(userId)));
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };
    
    fetchUserInfo();
    fetchRequestsWithPagination();
  }, []);

  const fetchRequestsWithPagination = async () => {
    try {
      const token = sessionStorage.getItem('token') || localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/api/supplier-leads`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch requests');
      }
      
      const data = await response.json();
      
      const processedData = data.map(item => ({
        id: item.id,
        supplierName: item.supplierName || item.supplier_name,
        cost: item.cost,
        description: item.description,
        dateEstimate: item.dateEstimate || item.date_estimate,
        nextPurchaseDate: item.nextPurchaseDate || item.next_purchase_date,
        status: item.status,
        approverName: item.approverName,
        actionDate: item.actionDate,
        note: item.note,
        user: item.user
      }));
      
      setRequests(processedData);
    } catch (error) {
      console.error('Error fetching supplier requests:', error);
      toast.error('Failed to fetch supplier requests');
      setRequests([]);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
    
    // Clear error when user types
    if (formErrors[name]) {
      setFormErrors({
        ...formErrors,
        [name]: undefined
      });
    }
  };

  const handleDateChange = (name, value) => {
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const validateForm = () => {
    const errors = {};
    
    if (!formValues.supplierName) {
      errors.supplierName = 'Supplier Name is required';
    }
    
    if (!formValues.cost) {
      errors.cost = 'Cost is required';
    } else if (isNaN(parseFloat(formValues.cost)) || parseFloat(formValues.cost) <= 0) {
      errors.cost = 'Cost must be a positive number';
    }
    
    if (!formValues.description) {
      errors.description = 'Description is required';
    }
    
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    setLoading(true);
    try {
      // Make a real API call to create a new supplier request
      const token = sessionStorage.getItem('token') || localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/api/supplier-leads`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(formValues),
      });
      
      if (!response.ok) {
        throw new Error('Failed to create supplier request');
      }
      
      // Get the response data which includes the new request ID
      const data = await response.json();
      
      // After successfully creating the request, notify approvers
      await fetch(`${BASE_URL}/api/supplier-leads/${data.id}/notify-approvers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ 
          action: 'created',
          leadData: {
            ...formValues,
            id: data.id
          }
        }),
      });
      
      toast.success('Supplier request created successfully');
      
      // Reset form
      setFormValues({
        supplierName: '',
        cost: '',
        description: '',
        dateEstimate: null,
        nextPurchaseDate: null,
      });
      
      // Refresh requests list
      fetchRequestsWithPagination();
    } catch (error) {
      console.error('Error creating supplier request:', error);
      toast.error('Failed to create supplier request');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (id, status) => {
    if (!userCanApproveReject) {
      toast.error('You do not have permission to perform this action');
      return;
    }

    // Open dialog for note input instead of immediately changing status
    setPendingAction({ id, status });
    setNoteText('');
    setNoteDialogOpen(true);
  };
  
  // Add new function to handle the actual status update
  const handleConfirmStatusChange = async () => {
    const { id, status } = pendingAction;
    setNoteDialogOpen(false);
    
    // Set the specific row ID as updating
    setUpdatingRowId(id);
    
    try {
      const token = sessionStorage.getItem('token') || localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/api/supplier-leads/${id}/status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ 
          status,
          note: noteText.trim() || null 
        }),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `Failed to ${status} request`);
      }
      
      const statusData = await response.json();
      
      // After successfully updating the status, notify all approvers
      // Get the request details first to include in the notification
      const leadResponse = await fetch(`${BASE_URL}/api/supplier-leads/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (leadResponse.ok) {
        const leadData = await leadResponse.json();
        
        // Send notification to all approvers
        await fetch(`${BASE_URL}/api/supplier-leads/${id}/notify-approvers`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ 
            action: status,
            leadData,
            approverName: statusData.approverName,
            actionDate: statusData.actionDate,
            note: noteText.trim() || null
          }),
        });
      }
      
      toast.success(`Request ${status === 'approved' ? 'approved' : 'rejected'} successfully`);
      
      // Refresh requests list while keeping pagination
      await fetchRequestsWithPagination();
    } catch (error) {
      console.error(`Error ${status} request:`, error);
      toast.error(error.message);
    } finally {
      // Always clear the loading state when done
      setUpdatingRowId(null);
      setPendingAction({ id: null, status: null });
    }
  };
  
  // Add new function to handle dialog close
  const handleCloseNoteDialog = () => {
    setNoteDialogOpen(false);
    setPendingAction({ id: null, status: null });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Create base columns without actions
  const baseColumns = [
    { field: 'id', headerName: 'ID', width: 60, flex: 0.5 },
    { field: 'supplierName', headerName: 'Supplier Name', width: 150, flex: 1 },
    { 
      field: 'cost', 
      headerName: 'Cost', 
      width: 120, 
      flex: 0.8,
      renderCell: (params) => {
        if (!params?.row?.cost) {
          return 'Not Available';
        }
        return `$${parseFloat(params.row.cost).toFixed(2)}`;
      }
    },
    { 
      field: 'description', 
      headerName: 'Description', 
      width: 180, 
      flex: 1.5,
      renderCell: (params) => {
        return (
          <Box sx={{ 
            overflow: 'auto', 
            maxHeight: '100px',  // Allows scrolling for very long content
            width: '100%'
          }}>
            {params.row.description}
          </Box>
        );
      }
    },
    { 
      field: 'dateEstimate', 
      headerName: 'Date Estimate', 
      width: 130,
      flex: 1,
      headerAlign: 'center',
      renderHeader: () => (
        <Box sx={{ 
          lineHeight: '1.2em', 
          whiteSpace: 'normal',
          textAlign: 'center'
        }}>
          Date<br />Estimate
        </Box>
      ),
      renderCell: (params) => {
        if (!params?.row?.dateEstimate) {
          return 'Not Available';
        }
        try {
          return new Date(params.row.dateEstimate).toLocaleDateString();
        } catch (e) {
          return 'Not Available';
        }
      }
    },
    { 
      field: 'nextPurchaseDate', 
      headerName: 'Next Purchase Date', 
      width: 160,
      flex: 1,
      headerAlign: 'center',
      renderHeader: () => (
        <Box sx={{ 
          lineHeight: '1.2em', 
          whiteSpace: 'normal',
          textAlign: 'center'
        }}>
          Next Purchase<br />Date
        </Box>
      ),
      renderCell: (params) => {
        if (!params?.row?.nextPurchaseDate) {
          return 'Not Available';
        }
        try {
          return new Date(params.row.nextPurchaseDate).toLocaleDateString();
        } catch (e) {
          return 'Not Available';
        }
      }
    },
    { 
      field: 'status', 
      headerName: 'Status', 
      width: 100, 
      flex: 0.8,
      renderCell: (params) => {
        if (!params?.row?.status) return <Box>Unknown</Box>;
        
        const status = params.row.status.toLowerCase();
        const approverInfo = params.row.approverName && params.row.actionDate 
          ? `${status.charAt(0).toUpperCase() + status.slice(1)} by ${params.row.approverName} on ${new Date(params.row.actionDate).toLocaleDateString()}`
          : status;
        
        return (
          <Tooltip title={approverInfo}>
            <Box 
              sx={{
                backgroundColor: status === 'approved' ? '#e6f7e6' : 
                             status === 'rejected' ? '#ffebee' : 
                             status === 'pending' ? '#fff8e1' : 'transparent',
                padding: '4px 8px',
                borderRadius: '4px',
                textTransform: 'capitalize'
              }}
            >
              {status}
            </Box>
          </Tooltip>
        );
      }
    },
    { 
      field: 'approverInfo', 
      headerName: 'Reviewer',
      width: 150,
      flex: 1,
      renderCell: (params) => {
        if (!params.row.approverName || !params.row.actionDate) {
          return 'Not Available';
        }
        return (
          <Box>
            {params.row.approverName}
            <Typography variant="caption" display="block" color="text.secondary">
              {params.row.actionDate}
            </Typography>
          </Box>
        );
      }
    },
    { 
      field: 'user',
      headerName: 'Created By', 
      width: 150,
      flex: 1,
      renderCell: (params) => {
        return params?.row?.user?.name || 'Unknown';
      }
    },
    { 
      field: 'approverNote', 
      headerName: 'Note',
      width: 150,
      flex: 1,
      renderCell: (params) => {
        if (!params.row.note) {
          return 'None';
        }
        return (
          <Tooltip title={params.row.note}>
            <Box sx={{ 
              maxWidth: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}>
              {params.row.note}
            </Box>
          </Tooltip>
        );
      }
    },
  ];

  // Action column definition
  const actionColumn = {
    field: 'actions',
    headerName: 'Actions',
    width: 120, // Reduced width for more compactness
    flex: 0.8,
    renderCell: (params) => {
      const rowId = params.row.id;
      const status = params.row.status.toLowerCase();
      
      // If this row is currently being updated, show a loading spinner
      if (updatingRowId === rowId) {
        return (
          <Box display="flex" justifyContent="center" width="100%">
            <CircularProgress size={24} />
          </Box>
        );
      }

      // Organize buttons in a cleaner layout with consistent spacing
      return (
        <Box sx={{ 
          display: 'flex', 
          gap: '4px',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%' 
        }}>
          {/* Edit button always shown for approvers */}
          <Tooltip title="Edit">
            <IconButton 
              color="primary" 
              onClick={() => handleEditRequest(params.row)}
              size="small"
              sx={{ padding: '4px' }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          
          {/* Conditional rendering for status buttons */}
          {status === 'approved' && (
            <Tooltip title="Reject">
              <IconButton 
                color="error" 
                onClick={() => handleStatusChange(rowId, 'rejected')}
                size="small"
                sx={{ padding: '4px' }}
              >
                <CancelIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}

          {status === 'rejected' && (
            <Tooltip title="Approve">
              <IconButton 
                color="success" 
                onClick={() => handleStatusChange(rowId, 'approved')}
                size="small"
                sx={{ padding: '4px' }}
              >
                <CheckCircleIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}

          {status === 'pending' && (
            <>
              <Tooltip title="Approve">
                <IconButton 
                  color="success" 
                  onClick={() => handleStatusChange(rowId, 'approved')}
                  size="small"
                  sx={{ padding: '4px' }}
                >
                  <CheckCircleIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Reject">
                <IconButton 
                  color="error" 
                  onClick={() => handleStatusChange(rowId, 'rejected')}
                  size="small"
                  sx={{ padding: '4px' }}
                >
                  <CancelIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      );
    }
  };

  // Conditionally add the action column based on user permissions
  const columns = userCanApproveReject ? [...baseColumns, actionColumn] : baseColumns;

  // Add function to handle opening the edit dialog
  const handleEditRequest = async (request) => {
    if (!userCanApproveReject) {
      toast.error('You do not have permission to edit requests');
      return;
    }
    
    setEditFormValues({
      id: request.id,
      supplierName: request.supplierName,
      cost: request.cost,
      description: request.description,
      dateEstimate: request.dateEstimate ? new Date(request.dateEstimate) : null,
      nextPurchaseDate: request.nextPurchaseDate ? new Date(request.nextPurchaseDate) : null,
    });
    setEditFormErrors({});
    setEditDialogOpen(true);
  };

  // Add function to handle edit input changes
  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditFormValues({
      ...editFormValues,
      [name]: value
    });
    
    // Clear error when user types
    if (editFormErrors[name]) {
      setEditFormErrors({
        ...editFormErrors,
        [name]: undefined
      });
    }
  };

  // Add function to handle edit date changes
  const handleEditDateChange = (name, value) => {
    setEditFormValues({
      ...editFormValues,
      [name]: value
    });
  };

  // Add function for validating edit form
  const validateEditForm = () => {
    const errors = {};
    
    if (!editFormValues.supplierName) {
      errors.supplierName = 'Supplier Name is required';
    }
    
    if (!editFormValues.cost) {
      errors.cost = 'Cost is required';
    } else if (isNaN(parseFloat(editFormValues.cost)) || parseFloat(editFormValues.cost) <= 0) {
      errors.cost = 'Cost must be a positive number';
    }
    
    if (!editFormValues.description) {
      errors.description = 'Description is required';
    }
    
    setEditFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Add function to handle submitting edit form
  const handleEditSubmit = async () => {
    if (!validateEditForm()) {
      return;
    }
    
    setLoading(true);
    try {
      const token = sessionStorage.getItem('token') || localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/api/supplier-leads/${editFormValues.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(editFormValues),
      });
      
      if (!response.ok) {
        throw new Error('Failed to update supplier request');
      }
      
      toast.success('Supplier request updated successfully');
      setEditDialogOpen(false);
      
      // Refresh requests list
      fetchRequestsWithPagination();
    } catch (error) {
      console.error('Error updating supplier request:', error);
      toast.error('Failed to update supplier request');
    } finally {
      setLoading(false);
    }
  };

  // Add function to close edit dialog
  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" mb={3}>Approval Request Form</Typography>
      
      {userCanApproveReject && (
        <Typography variant="subtitle1" mb={2} sx={{ color: 'primary.main' }}>
          You have approver permissions and can see all supplier requests.
        </Typography>
      )}
      

      
      <Paper sx={{ mb: 3 }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange} 
          centered
          variant="fullWidth"
          sx={{
            '& .MuiTabs-indicator': {
              transition: 'left 0.3s cubic-bezier(0.4, 0, 0.2, 1), width 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
              height: 3,
              borderRadius: '3px 3px 0 0',
            },
            '& .MuiTab-root': {
              minWidth: 0,
              padding: '12px 16px',
              fontWeight: 500,
              textTransform: 'none',
              transition: 'all 0.3s ease',
            }
          }}
        >
          <Tab 
            label="ADD NEW REQUEST" 
            sx={{
              flex: 1,
              '&.Mui-selected': {
                fontWeight: 600,
              }
            }}
          />
          <Tab 
            label="VIEW REQUESTS" 
            sx={{
              flex: 1,
              '&.Mui-selected': {
                fontWeight: 600,
              }
            }}
          />
        </Tabs>
      </Paper>

      {tabValue === 0 ? (
        <Paper sx={{ p: 3 }}>
          <Typography variant="h6" mb={2}>New Approval Request</Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="supplierName"
                  name="supplierName"
                  label="Supplier Name *"
                  value={formValues.supplierName}
                  onChange={handleInputChange}
                  error={Boolean(formErrors.supplierName)}
                  helperText={formErrors.supplierName}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="cost"
                  name="cost"
                  label="Cost ($) *"
                  type="number"
                  value={formValues.cost}
                  onChange={handleInputChange}
                  error={Boolean(formErrors.cost)}
                  helperText={formErrors.cost}
                  margin="normal"
                  inputProps={{ min: 0, step: 0.01 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="description"
                  name="description"
                  label="Description *"
                  multiline
                  rows={4}
                  value={formValues.description}
                  onChange={handleInputChange}
                  error={Boolean(formErrors.description)}
                  helperText={formErrors.description}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Date Estimate"
                    value={formValues.dateEstimate}
                    onChange={(value) => handleDateChange('dateEstimate', value)}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        margin: 'normal',
                        error: Boolean(formErrors.dateEstimate),
                        helperText: formErrors.dateEstimate
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Next Purchase Date"
                    value={formValues.nextPurchaseDate}
                    onChange={(value) => handleDateChange('nextPurchaseDate', value)}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        margin: 'normal',
                        error: Boolean(formErrors.nextPurchaseDate),
                        helperText: formErrors.nextPurchaseDate
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                  sx={{ mt: 2 }}
                >
                  {loading ? <CircularProgress size={24} /> : 'Submit Request'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      ) : (
        <Paper sx={{ 
          p: 3, 
          minHeight: 400, // Starting minimum height
          borderRadius: '8px', 
          boxShadow: '0 2px 10px rgba(0,0,0,0.08)' 
        }}>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
            </Box>
          ) : requests.length === 0 ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="300px">
              <Typography variant="h6" color="text.secondary">
                No Data
              </Typography>
            </Box>
          ) : (
            <DataGrid
              rows={requests}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: currentPage, pageSize: pageSize },
                },
              }}
              pageSizeOptions={[5, 10, 20]}
              onPaginationModelChange={(model) => {
                setCurrentPage(model.page);
                setPageSize(model.pageSize);
              }}
              autoHeight
              disableColumnMenu
              disableColumnResize={false}
              getRowHeight={() => 'auto'}
              getEstimatedRowHeight={() => 100}
              componentsProps={{
                pagination: {
                  sx: {
                    '.MuiTablePagination-toolbar': {
                      justifyContent: 'flex-end',
                    },
                    '.MuiTablePagination-displayedRows': {
                      margin: '0 auto 0 0',
                    },
                    '.MuiTablePagination-selectLabel': {
                      margin: '0 auto 0 0',
                    }
                  }
                }
              }}
              sx={{
                '& .MuiDataGrid-cell': {
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                  lineHeight: '1.5em',
                  padding: '8px',
                  alignItems: 'flex-start'
                },
                border: 'none',
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: '#f5f5f5',
                  borderRadius: '4px 4px 0 0',
                  whiteSpace: 'normal',
                  lineHeight: '1.2em',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  lineHeight: '1.2em',
                  whiteSpace: 'normal',
                  overflow: 'visible',
                },
                '& .MuiDataGrid-row:nth-of-type(even)': {
                  backgroundColor: '#fafafa',
                },
                '& .MuiDataGrid-row:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
                '& .MuiDataGrid-footerContainer': {
                  justifyContent: 'flex-end',
                  borderTop: '1px solid #f0f0f0',
                },
                '& .MuiDataGrid-virtualScroller': {
                  overflowX: 'auto',
                },
                '& .MuiTablePagination-root': {
                  marginRight: '8px',
                },
                width: '100%',
                boxSizing: 'border-box'
              }}
            />
          )}
        </Paper>
      )}

      {/* Add Dialog for Note Input */}
      <Dialog open={noteDialogOpen} onClose={handleCloseNoteDialog}>
        <DialogTitle>
          {pendingAction.status === 'approved' ? 'Approve Request' : 'Reject Request'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {pendingAction.status === 'approved' 
              ? 'Add an optional note about why you are approving this request:'
              : 'Please provide an optional reason for rejecting this request:'}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="note"
            label="Note (Optional)"
            type="text"
            fullWidth
            multiline
            rows={3}
            variant="outlined"
            value={noteText}
            onChange={(e) => setNoteText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNoteDialog}>Cancel</Button>
          <Button 
            onClick={handleConfirmStatusChange} 
            variant="contained" 
            color={pendingAction.status === 'approved' ? 'success' : 'error'}
          >
            {pendingAction.status === 'approved' ? 'Approve' : 'Reject'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Add Dialog for Edit Form */}
      <Dialog open={editDialogOpen} onClose={handleCloseEditDialog} maxWidth="md" fullWidth>
        <DialogTitle>Edit Supplier Request</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="edit-supplierName"
                name="supplierName"
                label="Supplier Name *"
                value={editFormValues.supplierName}
                onChange={handleEditInputChange}
                error={Boolean(editFormErrors.supplierName)}
                helperText={editFormErrors.supplierName}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="edit-cost"
                name="cost"
                label="Cost ($) *"
                type="number"
                value={editFormValues.cost}
                onChange={handleEditInputChange}
                error={Boolean(editFormErrors.cost)}
                helperText={editFormErrors.cost}
                margin="normal"
                inputProps={{ min: 0, step: 0.01 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="edit-description"
                name="description"
                label="Description *"
                multiline
                rows={4}
                value={editFormValues.description}
                onChange={handleEditInputChange}
                error={Boolean(editFormErrors.description)}
                helperText={editFormErrors.description}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date Estimate"
                  value={editFormValues.dateEstimate}
                  onChange={(value) => handleEditDateChange('dateEstimate', value)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      margin: 'normal',
                      error: Boolean(editFormErrors.dateEstimate),
                      helperText: editFormErrors.dateEstimate
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Next Purchase Date"
                  value={editFormValues.nextPurchaseDate}
                  onChange={(value) => handleEditDateChange('nextPurchaseDate', value)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      margin: 'normal',
                      error: Boolean(editFormErrors.nextPurchaseDate),
                      helperText: editFormErrors.nextPurchaseDate
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog}>Cancel</Button>
          <Button 
            onClick={handleEditSubmit} 
            variant="contained" 
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Save Changes'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SupplierRequests; 